import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class MainNavigation extends Component {

  render() {
    return (
      <div className="main_navigation vh-fix" style={this.props.style}>
        <div className="main_nav_container">
          {/*<a href="https://www.youtube.com/playlist?list=PLs24WCx4b-wyFPuunxKrkR00ylq59FZUH"
            target="_blank" className="link_ext" rel="noopener noreferrer">
            <svg viewBox="0 0 118 13">
              <text x="0" y="12">CLUBMOBILE</text>
            </svg>
          </a>*/}
          <Link to="/function" className="link_int">
            <svg viewBox="0 0 93 13">
              <text x="0" y="12">FUNCTION</text>
            </svg>
          </Link>
          {(this.props.urlRecord && this.props.recordDisplayOption === 'external') &&
            <a href={this.props.urlRecord} target="_blank" className="link_ext" rel="noopener noreferrer">
              <svg viewBox="0 0 81 13">
              <text x="0" y="12">RELEASE</text>
            </svg>
            </a>
          }
          {(!this.props.urlRecord || this.props.recordDisplayOption === 'internal') &&
            <Link to="/record" className="link_int">
              <svg viewBox="0 0 81 13">
              <text x="0" y="12">RELEASE</text>
            </svg>
            </Link>
          }
          <a href="https://chilloutroom.club/"
            target="_blank" className="link_ext" rel="noopener noreferrer">
            <svg viewBox="0 0 145 13">
              <text x="0" y="12">CHILLOUTROOM</text>
            </svg>
          </a>
          <Link to="/clubcast" className="link_int">
            <svg viewBox="0 0 96 13">
              <text x="0" y="12">CLUBCAST</text>
            </svg>
          </Link>
          {(this.props.urlBoutique && this.props.boutiqueDisplayOption === 'external') &&
            <a href={this.props.urlBoutique} target="_blank" className="link_ext" rel="noopener noreferrer">
              <svg viewBox="0 0 95 13">
                <text x="0" y="12">BOUTIQUE</text>
              </svg>
            </a>
          }
          {(!this.props.urlBoutique || this.props.boutiqueDisplayOption === 'internal') &&
            <Link to="/boutique" className="link_int">
              <svg viewBox="0 0 95 13">
                <text x="0" y="12">BOUTIQUE</text>
              </svg>
            </Link>
          }
          <Link to="/connection" className="link_int">
            <svg viewBox="0 0 120 13">
              <text x="0" y="12">CONNECTION</text>
            </svg>
          </Link>
        </div>
      </div>
    );
  }
}

export default MainNavigation;
