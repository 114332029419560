import React, { Component } from 'react';

import MainLandingDates from './MainLandingDates';

class MainLanding extends Component {

    render() {
      const { landingImage, landingImage_active, bookingEmail, bookingEmail_active, landingDates_active,
        featuredBlock_name, featuredBlock_active, featuredBlock_image, featuredBlock_url, featuredBlock_url_text, featuredBlock_position,
        featuredBlock2_active, featuredBlock2_name, featuredBlock2_image, featuredBlock2_url, featuredBlock2_url_text, featuredBlock2_position } = this.props.landing || '';
      const imageSrc = (landingImage) ? landingImage.secure_url : '';
      const mailTo = (bookingEmail) ? `mailto:${bookingEmail}` : '';

      // add feat1 class to featured block IF
      // landingImage is NOT active
      // if only one block is active: add to that block
      // if two blocks active: add to the first block in the sequence
        // featuredBlock1 = active & above: if !landingImage_active && featuredBlock_active && featuredBlock_position === 'above' && featuredBlock2_active
        // featuredBlock2 = active & above: if !landingImage_active && featuredBlock_active && featuredBlock_position === 'below' && featuredBlock2_active && featuredBlock2_position === 'above'
        // featuredBlock1 = active & below: if !landingImage_active && featuredBlock_active && featuredBlock_position === 'below'

      //let mainFeatureClassFeat1 = `main_feature feat1 vh-fix`;
      //let mainFeatureClassDefault = `main_feature vh-fix`;

      return (
        <div className="main_landing vh-fix">
          {(landingImage && landingImage_active) &&
            <div className="main_image vh-fix"><img className="vh-fix" src={imageSrc} alt="Clubby Boy" /></div>
          }
          {(featuredBlock_active === true && featuredBlock_position === 'above') && (
            <div className={(!landingImage_active) ? `main_feature feat1 vh-fix` : `main_feature vh-fix`}>
              <div className="full">
                <a href={featuredBlock_url} target="_blank" title={featuredBlock_name} rel="noopener noreferrer">
                  <img src={featuredBlock_image.secure_url} alt={featuredBlock_name} />
                  <div className="feat_heading">{featuredBlock_url_text}</div>
                </a>
              </div>
            </div>
          )}
          {(featuredBlock2_active === true && featuredBlock2_position === 'above') && (
            <div className={(!landingImage_active && featuredBlock_active && featuredBlock_position === 'below') || (!landingImage_active && !featuredBlock_active) ? `main_feature feat1 vh-fix` : `main_feature vh-fix`}>
              <div className="full">
                <a href={featuredBlock2_url} target="_blank" title={featuredBlock2_name} rel="noopener noreferrer">
                  <img src={featuredBlock2_image.secure_url} alt={featuredBlock2_name} />
                  <div className="feat_heading">{featuredBlock2_url_text}</div>
                </a>
              </div>
            </div>
          )}
          {(landingDates_active && <MainLandingDates />)}
          {(bookingEmail_active && bookingEmail && bookingEmail.length > 0) &&
            <div className="main_booking">
              <a href={mailTo}><span>BOOKING:</span> {bookingEmail}</a>
            </div>
          }
          {(featuredBlock_active === true && featuredBlock_position === 'below') && (
            <div className={(!landingImage_active && featuredBlock2_active && featuredBlock2_position === 'above') || (!landingImage_active && !featuredBlock2_active) ? `main_feature feat1 vh-fix` : `main_feature vh-fix`}>
              <div className="full">
                <a href={featuredBlock_url} target="_blank" title={featuredBlock_name} rel="noopener noreferrer">
                  <img src={featuredBlock_image.secure_url} alt={featuredBlock_name} />
                  <div className="feat_heading">{featuredBlock_url_text}</div>
                </a>
              </div>
            </div>
          )}
          {(featuredBlock2_active === true && featuredBlock2_position === 'below') && (
            <div className={(!landingImage_active && !featuredBlock_active) ? `main_feature feat1 vh-fix` : `main_feature vh-fix`}>
              <div className="full">
                <a href={featuredBlock2_url} target="_blank" title={featuredBlock2_name} rel="noopener noreferrer">
                  <img src={featuredBlock2_image.secure_url} alt={featuredBlock2_name} />
                  <div className="feat_heading">{featuredBlock2_url_text}</div>
                </a>
              </div>
            </div>
          )}
        </div>
      );
    }

}

export default MainLanding;
