import React, { Component } from 'react';

class MainBlocks extends Component {

    constructor(props) {
      super(props);

      this.state = {
        blocks: []
      }
    }

    // Get Operating System from UserAgent
    // https://stackoverflow.com/a/38241481
    _getOS() {
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
      }

      return os;
    }

    _getOSLink(os, osUrls) {
      if (os === 'Android') return osUrls.android;
      else if (os === 'iOS') return osUrls.iphone;
      else return osUrls.desktop;
    }

    _buildBlocks(propBlocks) {
      const userOS = this._getOS();

      const osUrl = this._getOSLink(userOS, {
          android: propBlocks.blockCast_url_android,
          iphone: propBlocks.blockCast_url_iphone,
          desktop: propBlocks.blockCast_url_desktop,
      })

      let blocks = [];
      let blockCast_position = propBlocks.blockCast_position;

      // set ordering of blocks before setting state
      for (let i = 1; i <= 7; i++) {
        let blockNum = `block${i}`;
        if (propBlocks['blockCast_active'] === true && (osUrl && osUrl !== '') && parseInt(blockCast_position) === i) {
          blocks.push({
            name: 'Clubcast',
            image: propBlocks.blockCast_image.secure_url,
            link: osUrl,
            width: propBlocks.blockCast_width,
          });
        }

        if (propBlocks[`${blockNum}_active`] === true) {
          blocks.push({
            name: propBlocks[`${blockNum}_name`],
            image: propBlocks[`${blockNum}_image`].secure_url,
            link: propBlocks[`${blockNum}_url`],
            width:  propBlocks[`${blockNum}_width`],
          });
        }
      }

      // insert clubcast block after all active blocks if not previously inserted
      if (propBlocks['blockCast_active'] === true && (osUrl && osUrl !== '') && parseInt(blockCast_position) === 8) {
        blocks.splice(propBlocks.blockCast_position-1, 0, {
          name: 'Clubcast',
          image: propBlocks.blockCast_image.secure_url,
          link: osUrl,
          width: propBlocks.blockCast_width,
        });
      }

      return blocks;
    }

    render() {
      let blocks = this._buildBlocks(this.props.blocks);

      if (blocks.length > 0) {
        return (
          <div className="main_blocks vh-fix">
            {blocks.length > 0 && blocks.map((block, idx) => (
              <div className={block.width === 'full' ? 'full' : 'half'} key={`b${idx}`}>
                <a href={block.link} target="_blank" title={block.name} rel="noopener noreferrer">
                  <img src={block.image} alt={block.name} />
                </a>
              </div>
            ))}
          </div>
        );
      } else return (<></>);
    }

}

export default MainBlocks;
