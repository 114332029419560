import axios from 'axios';
import React, { Component } from 'react';
import Swipeable from 'react-swipeable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import tixImg from '../img/function/tickets.png';
import raLogo from '../img/function/residentadvisor.png';
import badmLogo from '../img/function/badm.jpg';

class ClubbyFunction extends Component {

  // FROM SETTINGS
  // functionUrlDefaultTickets
  // functionUrlDefaultRA
  // functionUrlDefaultArt

  constructor(props) {
    super(props);

    this.arrowActiveDelay = 500; // in ms
    this.state = {
      functions: [],
      currentFunction: {},
      currentIdx: -1,
      arrowActive: null,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleArrowLeft = this.handleArrowLeft.bind(this);
    this.handleArrowRight = this.handleArrowRight.bind(this);
  }

  componentDidMount() {
    // add keydown event listeners
    document.addEventListener("keydown", this.handleKeyDown);

    // get data from keystone API route
    axios.get('/api/functions')
      .then(res => {
        const functions = res.data.functions.filter(fn => fn.image);

        this.setState({
          functions,
          currentIdx: 0,
          currentFunction: functions[0],
        });
      });
  }

  componentWillUnmount() {
    // remove keydown event listeners
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  arrowLeft() {
    let curr = this.state.currentIdx;
    if (curr <= 0) curr = 0;
    else curr -= 1;

    this.setState({
      currentIdx: curr,
      currentFunction: this.state.functions[curr],
      arrowActive: 'left'
    });

    setTimeout(() => {
      this.setState({
        arrowActive: null
      });
    }, this.arrowActiveDelay);
  }

  arrowRight() {
    let curr = this.state.currentIdx;
    let maxLength = this.state.functions.length;
    if (curr >= maxLength-1) curr = maxLength-1;
    else curr += 1;

    this.setState({
      currentIdx: curr,
      currentFunction: this.state.functions[curr],
      arrowActive: 'right'
    });

    setTimeout(() => {
      this.setState({
        arrowActive: null
      });
    }, this.arrowActiveDelay);
  }

  arrowIconLeft() {
    if (this.state.arrowActive === 'left') {
      return ['far', 'angle-double-left'];
    } else {
      return ['far', 'angle-left'];
    }
  }

  arrowIconRight() {
    if (this.state.arrowActive === 'right') {
      return ['far', 'angle-double-right'];
    } else {
      return ['far', 'angle-right'];
    }
  }

  handleArrowLeft(event) {
    this.arrowLeft();
    event.preventDefault();
  }

  handleArrowRight(event) {
    this.arrowRight();
    event.preventDefault();
  }

  handleKeyDown(event) {
    switch(event.key) {
      case "ArrowUp":
      case "ArrowLeft":
      case "j":
        this.arrowLeft();
        break;
      case "ArrowDown":
      case "ArrowRight":
      case "k":
        this.arrowRight();
        break;
      default:
        break;
    }

    event.preventDefault();
  }

  render() {
    let currentFunction = (this.state.functions.length > 0) ? this.state.functions[this.state.currentIdx] : null;
    let showLeftArrow = this.state.currentIdx > 0;
    let showRightArrow = this.state.currentIdx >= 0 && this.state.currentIdx < this.state.functions.length-1;
    let imageTitle = (currentFunction && currentFunction.image) ? currentFunction.name : '';

    let imageLink;
    let linkToImage = (currentFunction && currentFunction.linkImageTo !== 'none');
    let isFacebookLink = false;
    if (currentFunction !== null) {
      if (currentFunction.linkImageTo === 'image' && currentFunction.image)
        imageLink = currentFunction.image.url;
      else if (currentFunction.linkImageTo === 'fb' && currentFunction.fbLink)
        imageLink = currentFunction.fbLink;
      else if (currentFunction.linkImageTo === 'ra' && currentFunction.raLink)
        imageLink = currentFunction.raLink;
      else if (currentFunction.linkImageTo === 'credit' && currentFunction.artistCredit)
        imageLink = currentFunction.artistCredit;

      if (currentFunction.fbLink) {
        if ((currentFunction.fbLink).indexOf('facebook.com') > -1)
          isFacebookLink = true;
        else
          isFacebookLink = false;
      }
    }

    let linkProps = {};
    if (linkToImage) {
      linkProps = {
        href: imageLink
      }
    }

    return (
      <div id="view_function" className="view view_overlay vh-fix">
        {showLeftArrow &&
          <div className="arrow arrow_left" title="Previous"
          onClick={this.handleArrowLeft}>
            <FontAwesomeIcon icon={this.arrowIconLeft()} size="2x" />
          </div>
        }
        {showRightArrow &&
          <div className="arrow arrow_right" title="Next"
            onClick={this.handleArrowRight}>
            <FontAwesomeIcon icon={this.arrowIconRight()} size="2x" />
          </div>
        }
        {currentFunction &&
          <Swipeable
            trackMouse
            onSwipedLeft={this.handleArrowRight}
            onSwipedRight={this.handleArrowLeft}
          >
            <div id="function_content">
              {currentFunction.linkImageTo === 'none' && (
                <span id="link_function" title={imageTitle}>
                  <img id="img_function" className="vh-fix" src={currentFunction.image.url} alt={imageTitle} />
                </span>
              )}
              {currentFunction.linkImageTo !== 'none' && (
                <a id="link_function" {...linkProps} target="_blank" title={imageTitle} rel="noopener noreferrer">
                  <img id="img_function" className="vh-fix" src={currentFunction.image.url} alt={imageTitle} />
                </a>
              )}
              <div className="row_icons">
                {(currentFunction.fbLink && isFacebookLink) &&
                  <a href={currentFunction.fbLink} target="_blank" title="Facebook Page" style={{ padding: '0 4px' }} rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} size="3x" />
                  </a>
                }
                {(currentFunction.fbLink && !isFacebookLink) &&
                  <a href={currentFunction.fbLink} target="_blank" title="Tickets" style={{ padding: '0 4px' }} rel="noopener noreferrer">
                    <img className="img_logo_icon img_logo_tix" src={tixImg} alt="Tickets" />
                  </a>
                }
                {(!currentFunction.fbLink && this.props.settings.functionUrlDefaultTix && (this.props.settings.functionUrlDefaultTix).indexOf('facebook.com') > -1) &&
                  <a href={this.props.settings.functionUrlDefaultTix} target="_blank" title="Facebook Page" style={{ padding: '0 4px' }} rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} size="3x" />
                  </a>
                }
                {(!currentFunction.fbLink && this.props.settings.functionUrlDefaultTix && (this.props.settings.functionUrlDefaultTix).indexOf('facebook.com') === -1) &&
                  <a href={this.props.settings.functionUrlDefaultTix} target="_blank" title="Tickets" style={{ padding: '0 4px' }} rel="noopener noreferrer">
                    <img className="img_logo_icon img_logo_tix" src={tixImg} alt="Tickets" />
                  </a>
                }

                {currentFunction.raLink &&
                  <a href={currentFunction.raLink} target="_blank" title="ResidentAdvisor Event Page" rel="noopener noreferrer">
                    <img className="img_logo_icon img_logo_ra" src={raLogo} alt="ResidentAdvisor Logo" />
                  </a>
                }
                {(!currentFunction.raLink && this.props.settings.functionUrlDefaultRA) &&
                  <a href={this.props.settings.functionUrlDefaultRA} target="_blank" title="ResidentAdvisor Event Page" rel="noopener noreferrer">
                    <img className="img_logo_icon img_logo_ra" src={raLogo} alt="ResidentAdvisor Logo" />
                  </a>
                }

                {currentFunction.artCredit &&
                  <a href={currentFunction.artCredit} target="_blank" title="Flyer Credits" rel="noopener noreferrer">
                    <img className="img_badm" src={badmLogo} alt="Bad M" />
                  </a>
                }
                {(!currentFunction.artCredit && this.props.settings.functionUrlDefaultArt) &&
                  <a href={this.props.settings.functionUrlDefaultArt} target="_blank" title="Flyer Credits" rel="noopener noreferrer">
                    <img className="img_badm" src={badmLogo} alt="Bad M" />
                  </a>
                }
              </div>
            </div>
          </Swipeable>
        }
      </div>
    );
  }
}

export default ClubbyFunction;
